<template>
    <div>
        <b-button
            id="save-preop-data-button"
            block
            :class="{saving: saving}"
            :disabled="isSaveDisabled || saving || !canEdit"
            @click="onSaveOrUpdatePreopData"
        >
            {{ saveButtonText }} <b-icon v-if="saving" icon="three-dots"></b-icon>
            <b-icon v-else icon="check"></b-icon>
        </b-button>
        <br />
        <b-button
            id="calculate-button"
            block
            :disabled="(isSaveDisabled && isCalculateDisabled) || !canEdit"
            @click="submitPreopdataCalculation"
        >
            {{ t('calcBtn_Calculate') }}
            <b-icon icon="arrow-right"></b-icon>
        </b-button>
    </div>
</template>
<script>
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {mapGetters, mapMutations} from 'vuex';
import {EyeSides} from '@/store/modules/preopdata';
import {ZoneCodes} from '@/constants/zone';
import {WarningRanges} from '@/constants/preopdata';
import {formatNumber} from '@/utilities/formatters';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

export default {
    name: 'CalculatorButtons',
    props: {},
    data() {
        return {
            saving: false,
        };
    },
    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),
        ...mapGetters('preopdata', [
            'highCylinderEdofSphericWarning',
            'activeRefractionTypeWarning',
            'reserveSphereRangeWarning',
            'clSphereNonZeroWarning',
            'isSaveDisabled',
            'isCalculateDisabled',
        ]),
        ...mapGetters({
            zone: 'zone/currentZone',
        }),
        canEdit() {
            return this.checkPermissions(
                {
                    [PERMISSIONS.PATIENT_PREOP_DATA]: PERMISSIONS_VALUES.READ_WRITE,
                },
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        saveButtonText() {
            return this.saving ? this.t('calcBtn_Saving') : this.t('calcBtn_Save');
        },
    },
    methods: {
        checkPermissions,
        ...mapMutations({
            showWarningModal: 'message/showWarningModal',
        }),
        async runHighCylinderEdofSphericWarning() {
            return new Promise((resolve) => {
                if (isEmpty(this.highCylinderEdofSphericWarning) || this.zone != ZoneCodes.OUS) {
                    return resolve(true);
                }
                const bothSides = this.highCylinderEdofSphericWarning.length > 1;
                const text = this.highCylinderEdofSphericWarning.map((side) =>
                    this.t('preop_Warning_highCylinderEdofSpheric', {
                        side,
                        cylinder: formatNumber(
                            WarningRanges.CYLINDER.MIN,
                            2,
                            this.currentUser.decimalSeparator
                        ),
                    })
                );
                const position =
                    this.highCylinderEdofSphericWarning[0] == EyeSides.OD ? 'left' : 'right';
                this.showWarningModal({
                    text: text.join('<br/>'), //.concat('<br/>', this.t('preop_recheckValues')),
                    position: bothSides ? 'center' : position,
                    ok: () => resolve(true),
                    cancel: () => resolve(false),
                    okTitle: this.t('continue'),
                });
            });
        },
        async runActiveRefractionTypeWarning() {
            return new Promise((resolve) => {
                if (isEmpty(this.activeRefractionTypeWarning)) {
                    return resolve(true);
                }
                const entries = Object.entries(this.activeRefractionTypeWarning);
                const bothSides = entries.length > 1;
                const text = bothSides
                    ? this.t('preop_Warning_activeRefractionType', {
                          [`refractionType${entries[0][0]}`]: this.t(entries[0][1]),
                          [`refractionType${entries[1][0]}`]: this.t(entries[1][1]),
                      })
                    : this.t('preop_Warning_sideActiveRefractionType', {
                          side: entries[0][0],
                          refractionType: this.t(entries[0][1]),
                      });

                const position = entries[0][0] == EyeSides.OD ? 'left' : 'right';
                this.showWarningModal({
                    text,
                    position: bothSides ? 'center' : position,
                    ok: () => resolve(true),
                    cancel: () => resolve(false),
                    okTitle: this.t('continue'),
                });
            });
        },
        async runReserveSphereRangeWarning() {
            return new Promise((resolve) => {
                if (isEmpty(this.reserveSphereRangeWarning) || this.zone != ZoneCodes.US) {
                    return resolve(true);
                }
                const bothSides = this.reserveSphereRangeWarning.length > 1;
                const text = this.reserveSphereRangeWarning.map((side) =>
                    this.t('preop_Warning_reserveSphereRange', {
                        side,
                        min: WarningRanges.SPHERE.MIN + 0.01,
                        max: WarningRanges.SPHERE.MAX,
                    })
                );
                const position =
                    this.reserveSphereRangeWarning[0] == EyeSides.OD ? 'left' : 'right';
                this.showWarningModal({
                    text: text.join('<br/>').concat('<br/>', this.t('preop_recheckValues')),
                    position: bothSides ? 'center' : position,
                    ok: () => resolve(true),
                    cancel: () => resolve(false),
                    okTitle: this.t('continue'),
                });
            });
        },
        async runClSphereNonZeroWarning() {
            return new Promise((resolve) => {
                if (isEmpty(this.clSphereNonZeroWarning)) {
                    return resolve(true);
                }
                const bothSides = this.clSphereNonZeroWarning.length > 1;
                const text = bothSides
                    ? this.t('preop_Warning_clSphereNonZero')
                    : this.t('preop_Warning_sideClSphereNonZero', {
                          side: this.clSphereNonZeroWarning[0],
                      });
                const position = this.clSphereNonZeroWarning[0] == EyeSides.OD ? 'left' : 'right';
                this.showWarningModal({
                    text,
                    position: bothSides ? 'center' : position,
                    ok: () => resolve(true),
                    cancel: () => resolve(false),
                    okTitle: this.t('continue'),
                });
            });
        },
        async submitPreopdataCalculation() {
            // At the start of the save and calculate action, we first perform a save operation if needed while disabling the emitter.
            // This prevents any page redirection from occurring before the calculation process is triggered.
            let results;
            if (!this.isSaveDisabled) {
                const data = await this.onSaveOrUpdatePreopData(false);
                results = data.results;
            }
            for await (const warning of [
                this.runHighCylinderEdofSphericWarning,
                this.runActiveRefractionTypeWarning,
                this.runReserveSphereRangeWarning,
                this.runClSphereNonZeroWarning,
            ])
                if (!(await warning())) {
                    // If we saved successfully preop data but calculation warning was not accepted the save process should flow
                    results && this.$emit('saved', results);
                    return;
                }
            const calculationResultsSets = await this.blockingRequest(
                'preopdata/submitPreopdataCalculation',
                {preOpDataSetId: get(results, 'preOpDataSetId')}
            );
            await this.blockingRequest('preopdata/saveCalculationResults', calculationResultsSets);
            // After completing the save and calculate process, we manually trigger the saved emitter.
            // This initiates redirection if required, which typically occurs during the first save of the preop data.
            results && this.$emit('saved', results);
        },
        async saveOrUpdatePreopData(emitter = true) {
            const {decimalSeparator} = this.currentUser;
            this.saving = true;
            try {
                const {data} = await this.blockingRequest(
                    'preopdata/saveOrUpdatePreopData',
                    decimalSeparator
                );
                if (data.success) {
                    /* This timeout control the toast to not disappear when saving first time
                    because the component get killed when redirecting to the path with preop data id */
                    setTimeout(
                        () =>
                            this.$bvToast.toast(this.t('preop_RecordSaved'), {
                                title: this.t('preop_RecordSavedTitle'),
                                variant: 'success',
                                solid: true,
                            }),
                        0
                    );
                    emitter && this.$emit('saved', data.results);
                } else {
                    this.$bvToast.toast(data.error.errors.join(' '), {
                        title: data.error.errorCodeDescription,
                        variant: 'warning',
                        solid: true,
                        noAutoHide: true,
                    });
                }
                return data;
            } finally {
                this.saving = false;
            }
        },
        async onSaveOrUpdatePreopData(emitter = true) {
            const results = await this.blockingRequest('preopdata/checkRefractiveCornealCylinder');
            const text = Object.values(EyeSides)
                .filter((side) => results[side])
                .map((side) =>
                    this.t('preop_Warning_sideRefractiveCornealCylinder', {
                        side,
                    })
                );
            const position = results.OD ? 'left' : 'right';
            if (results.OD || results.OS) {
                return new Promise((resolve) => {
                    this.showWarningModal({
                        text: text.join('<br/>').concat('<br/>', this.t('preop_recheckValues')),
                        position: results.areAllFailed ? 'center' : position,
                        ok: async () => {
                            const result = await this.saveOrUpdatePreopData(emitter);
                            resolve(result);
                        },
                        okTitle: this.t('continue'),
                    });
                });
            }
            return await this.saveOrUpdatePreopData(emitter);
        },
    },
};
</script>
<style scoped>
button[disabled] {
    background: rgba(255, 255, 255);
    opacity: 0.15;
    color: #616469;
    font-weight: 600;
    font-size: 0.857rem;
    line-height: 1.14rem;
    cursor: not-allowed;
}

button.saving[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
}
</style>
